import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "sale-item-categories";

export const getSaleItems = createAsyncThunk(
  `${reducerName}/sale-items`,
  async ({ categoryCode }, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.saleItemCategory.saleItems,
        data: { categoryCode },
      });

      return { categoryCode, data };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getSaleItemById = createAsyncThunk(
  `${reducerName}/sale-item-by-id`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.saleItemCategory.saleItemById,
        urlParams: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const calculateSaleItemPrice = createAsyncThunk(
  `${reducerName}/calculate-sale-item-price`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.saleItemCategory.calculatePrice,
        urlParams: params,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const calculateSaleItemNextPaymentDate = createAsyncThunk(
  `${reducerName}/calculate-sale-item-next-payment-date`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.saleItemCategory.calculateNextPaymentDate,
        urlParams: params,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const saleItemSlice = createSlice({
  name: reducerName,
  initialState: {
    list: {},
    saleItemById: {},
    saleItemPrice: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSaleItems.fulfilled,
      (state, { payload: { categoryCode, data } }) => {
        state.list[categoryCode] = data;
      }
    );
    builder.addCase(getSaleItemById.fulfilled, (state, { payload }) => {
      state.saleItemById = payload;
    });
    builder.addCase(calculateSaleItemPrice.pending, (state) => {
      state.saleItemPrice = {};
    });
    builder.addCase(calculateSaleItemPrice.fulfilled, (state, { payload }) => {
      state.saleItemPrice = payload;
    });
    builder.addCase(
      calculateSaleItemNextPaymentDate.fulfilled,
      (state, { payload }) => {
        const nextPaymentDate = payload.nextPaymentDate;
        state.saleItemPrice = {
          ...state.saleItemPrice,
          nextPaymentDate: nextPaymentDate,
        };
      }
    );
  },
});

export const actions = saleItemSlice.actions;

export default saleItemSlice.reducer;
